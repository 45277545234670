import { ReactElement } from 'react';

import fetchContentfulData from 'api/contentful-fetch';
// import { Banner, ComponentGenerator, HomeHero } from 'components/CMS';
import { ExtendedNextPageContext, ServerSideProps, UnknownObjectAny } from 'types/global.types';
import homepageQuery from '../graphql/queries/homepage';
import { graphQLErrorHandler } from 'utils/graphQLHandlers';
import { CMSHomePageProps } from 'types/cms/homeFragments';
import { ComponentGenerator, HomeHeroSingleImage } from 'components/CMS';
import PageContainer from 'components/containers/PageContainer';

const Home = ({ content }: CMSHomePageProps): ReactElement => {
  return (
    <>
      <HomeHeroSingleImage {...content} />
      <PageContainer removeHero>
        {content?.bodyCollection?.items?.map((component: UnknownObjectAny, index: number) => (
          <ComponentGenerator
            key={`homepage-component-${index}`}
            __typename={component.__typename}
            {...component}
          />
        ))}
      </PageContainer>
    </>
  );
};

export async function getServerSideProps(context: ExtendedNextPageContext): ServerSideProps {
  const { preview = false } = context;
  const content = (await fetchContentfulData(
    { query: homepageQuery, variables: { preview } },
    preview
  )) as UnknownObjectAny;

  graphQLErrorHandler('homepageQuery', content?.errors, homepageQuery);

  return { props: { content: content?.data?.homePage || null } };
}

export default Home;
